import React, { useState } from "react";
import styles from "./FormSection.module.css";
import { emailValid } from "utils/validation";
import ContactDetails from "../ContactDetails/ContactDetails";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { mainModule } from "process";
export default function FormSection() {
  const { t } = useTranslation("translation", { keyPrefix: "Contact" });
  const [toast, setToast] = useState<boolean | any>(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setToast(false);
    let formDetails = {
      name: "",
      email: "",
      message: "",
    };
    setIsSubmitting(true);
    setErrors({
      name: "",
      email: "",
      message: "",
    });
    var isError = false;
    if (!name) {
      setErrors((prevState) => ({ ...prevState, name: "Please Enter Name" }));
      isError = true;
    }
    if (!emailValid(email)) {
      setErrors((prevState) => ({
        ...prevState,
        email: "Please Enter Valid Email",
      }));
      isError = true;
    }
    if (!message) {
      setErrors((prevState) => ({
        ...prevState,
        message: "Please Enter A Message",
      }));
      isError = true;
    }
    if (isError) {
      console.log(errors);
      setIsSubmitting(false);
      return;
    }
    formDetails.name = name;
    formDetails.email = email;
    formDetails.message = message;

    try {
      const response = await axios({
        method: "post",
        url: "https://3uutr7rpwby4jag4dxnqehjwve0hnqwq.lambda-url.ap-northeast-1.on.aws/",
        headers: {},
        data: formDetails,
      });

      setIsSubmitting(false);
      setName("");
      setEmail("");
      setMessage("");
      setToast({ status: response.status });
      setTimeout(function () {
        setToast(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      setToast({ status: 400 });
      setTimeout(function () {
        setToast(false);
      }, 3000);
    }
  };
  return (
    <section className={"section-100 " + styles.section}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h3 className="pre-heading">{t("preHeading")}</h3>
            <h2 className="heading u-left mb-4">{t("heading")}</h2>
            <ContactDetails />
          </div>
          <div className="col-md-6">
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.form_group}>
                <div>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={name != "" ? styles.input_filled : ""}
                  />
                  <label className={styles.label} htmlFor="name">
                    {t("name")} <span>*</span>
                  </label>
                  <span
                    className={
                      errors.name
                        ? `${styles.underline} ${styles.error}`
                        : styles.underline
                    }
                  ></span>
                </div>
                {errors.name && (
                  <div className={styles.error_message}>{errors.name}</div>
                )}
              </div>
              <div className={styles.form_group}>
                <div>
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={email != "" ? styles.input_filled : ""}
                  />
                  <label className={styles.label} htmlFor="email">
                    {t("mail")} <span>*</span>
                  </label>
                  <span
                    className={
                      errors.email
                        ? `${styles.underline} ${styles.error}`
                        : styles.underline
                    }
                  ></span>
                </div>
                {errors.email && (
                  <span className={styles.error_message}>{errors.email}</span>
                )}
              </div>
              <div className={styles.form_group}>
                <div>
                  <textarea
                    rows={5}
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    className={message != "" ? styles.input_filled : ""}
                  ></textarea>
                  <label className={styles.label} htmlFor="message">
                    {t("message")} <span>*</span>
                  </label>
                  <span
                    className={
                      errors.message
                        ? `${styles.underline} ${styles.error}`
                        : styles.underline
                    }
                  ></span>
                </div>
                {errors.message && (
                  <div className={styles.error_message}>{errors.message}</div>
                )}
              </div>
              <div className={styles.form_group}>
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? t("submitting") : t("submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {toast && (
        <div
          className={
            toast.status === 200
              ? styles.toast
              : `${styles.toast} ${styles.error}`
          }
        >
          <p>
            {toast.status === 200
              ? t("Form Submitted Successfully")
              : t("Something Went Wrong")}
          </p>
          <span></span>
        </div>
      )}
    </section>
  );
}
